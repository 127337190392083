* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-size-adjust: 0.45;
}

body {
    @extend %body-copy;
    background-color: $colorWhiteBackground;
}

button {
    cursor: pointer;
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
    text-transform: none;
}

.responsive-object {
    position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.DropDown__Select__control {
    svg {
        transform: rotate(0);
        transition: transform 0.4s ease-in-out;
    }

    &--menu-is-open {
        svg {
            transform: rotate(-180deg);
        }
    }
}

.grecaptcha-badge {
  visibility: hidden;
}
