#CookieConsent .cookie-popup {

    .content {
        padding: 2rem 2rem 2.4rem 2rem !important;

       @include media(ml) {
            padding: 4rem 4rem 2.3rem 4rem !important;
        } 
    }

    & > div {
        max-width: 586px;
        border-radius: 0 !important;
    }

    #cc-label {
        font-size: 2.1rem;
        text-align: left;
        letter-spacing: 0.5px;
        line-height: 29px;

        @include media(ml) {
            font-size: 2.4rem;
        }
    }

    #cc-description {
        max-width: none !important;
        p {
            font-size: 1.6rem;

            @include media(ml) {
                font-size: 1.8rem;
            }
        }
    }

    .actions {
        max-width: 100% !important;

        div {
            &:first-child {
                border-right: none !important;
            }
            &:last-child {
                border-color: $colorGray300 !important; 
            }
        }

        button {
            padding: 15px !important;
            font-size: 1.4rem !important;
            font-weight: 400 !important;
            line-height: 17px !important;
            letter-spacing: 0.2px;
            border-radius: 50px !important;

            @include media(ml) {
                font-size: 1.8rem !important;
            }

            transition: transform $transition !important;
            transform: translateY(0px);
            &:hover {
                transform: translateY(-4px);
            }
        }

        span {
            font-size: 1.4rem !important;
            font-weight: 400 !important;
            line-height: 17px !important;
            letter-spacing: 0.2px;

            &::before {
                width: 3px !important;
                height: 7px !important;
                left: calc(100% - 1.24em)!important;
            }

            &::after {
                width: 3px !important;
                height: 11px !important;
                left: calc(100% - 0.9em) !important;
            }
            @include media(ml) {
                margin-left: 25px;
                font-size: 1.8rem !important;

                &::before {
                    left: calc(100% - 0.98em)!important;
                }

                &::after {
                    left: calc(100% - 0.7em) !important;
                }
            }


        }
    }

    .cb-statistics {
        height: 24px !important;
        width: 50px !important;
        background-color: $colorGray500 !important;

        &::before {
            top: 2px !important;
            left: 2px !important;
            width: 20px !important;
            height: 20px !important;
        }

        &:checked {
            background-color: $colorPink !important;

            &::before {
                left: 28px !important;
            }
        }
    }

    .details {

    }

    #cc-b-details {
        text-align: center !important;

        span {
            font-size: 1.4rem !important;

            @include media(ml) {
                font-size: 1.6rem !important;
            }

            &::before {
                height: 11px !important;
                width: 8px !important;
                background-color: $colorGray700;
            }
        }

    }

}
