//@import './includes.scss';
@use "sass:math";

$colorBlack800: #181818;
$fontBrand: 'Futura', sans-serif;
$colorPink: #de005d;
$colorGray200: #d9d9d9;
$colorGray40: #f6f6f6;
$colorWhite: #ffffff;
$assetPath: '/assets/';
$colorPink600: #de005d;
$colorWhiteBackground: #fcfcfc;
$colorGray10: #fcfcfc;
$colorPink300: #ffe1ec;

$breakpoints: (
    xs: 375px,
    s: 532px,
    m: 768px,
    ml: 1024px,
    ls: 1200px,
    l: 1440px,
    xl: 1680px,
    xxl: 1930px,
);

@mixin media($key) {
    @if $key == 'd' {
        @content;
    } @else if map-has-key($breakpoints, $key) {
        @media (min-width: map-get($breakpoints, $key)) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}


.leaflet-tooltip.geojson-label {
    height: 20px;
    width: 20px;
    background-color: $colorBlack800;
    margin-left: 1rem;
    border-radius: 50%;
    border: 2px solid $colorWhite;

    @include media(m) {
        width: auto;
        height: auto;
        background: #fff;
        color: #000;
        box-shadow: none;
        opacity: 1;
        border-radius: 22px;
        padding: 8px 30px;
        font-size: 1.4rem;
    }
}

//.leaflet-container {
//    width: 100%;
//    min-height: 54rem;
//    background: #ddd;
//    outline: 0;
//    font: 12px/1.5 'Helvetica Neue', Arial, Helvetica, sans-serif;
//    overflow: hidden;
//    -webkit-tap-highlight-color: transparent;
//
//    a {
//        -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
//        color: #0078a8;
//    }
//
//    @include media(m) {
//        min-height: 90rem;
//    }
//}

//.leaflet-tooltip {
//    color: $colorWhite;
//    border: none;
//    background: transparent;
//}

//.leaflet-tile-pane {
//    filter: brightness(100%) grayscale(100%);
//    opacity: 1;
//    z-index: 200;
//}

.marker-cluster.leaflet-marker-icon {
    color: $colorBlack800;
    background-color: $colorWhite;
    font-family: $fontBrand;
    font-size: 1.4rem;
    width: 42px !important;
    height: 42px !important;

    & div {
        background-color: $colorBlack800;
        width: 38px;
        height: 38px;
        border-radius: 20px;
        margin-left: 2px;
        margin-top: 2px;
    }
    & span {
        color: #fff;
        font-family: $fontBrand;
        font-size: 1.4rem;
        line-height: 40px;
    }

}

.leaflet-control-container {
    position: absolute;
    right: 5rem;
    top: 0;
}
